import React from 'react';

const LibraryItem = prop => {
    const { name, picture } = prop

    return (
        <div className='App-libraryItem'>
            <a href={`${prop.url}`} target="_blank" rel="noopener noreferrer">
                <div className='container'>
                    <div className='column-33'>
                        <img src={`data:image/png;base64, ${picture}`} alt="Icon" className='Picture' />
                    </div>
                    <div className='column-66'>
                        <div>
                            <h3>{name}</h3>
                        </div>
                        <div>
                            {prop.description}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}
export default LibraryItem