import './App.css';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.css';
import LibraryContainer from './Components/LibraryContainer';
import Footer from './Components/Footer';

// Main component
function App() {
  return (
    <>
      <div className="App">
        <div className='App-container'>
          <div className='App-header'>
            <Header />
          </div>
          <div className='App-libraryContainer'>
            <LibraryContainer />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}



export default App;
