import React, { useState, useEffect } from 'react';
import LibraryItem from './LibraryItem';
import { Spinner } from "react-bootstrap";

const LibraryContainer = () => {
    // console.log("Library")

    const [error, setError] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [websites, setWebsites] = useState([]);

    useEffect(() => {
        //console.log("useEF")
        fetch('https://5d3db592-9b18-4b37-bc83-53205b0090fb.eu.jetveo.io/api/website', {
            method: 'GET',
            headers: { 'jv-api-key': 'Abb22AM4HIcFzxua5zVWgkJOlLpaD1GC' }
        })
            .then(response => response.json())
            .then(
                (data) => {
                    console.log(data);
                    setWebsites(data.items);
                    setIsLoaded(true);
                    const sorted = data.items.sort((a, b) => { return a.displayOrder > b.displayOrder ? 1 : -1 })
                        .map(({ id, name, displayOrder, description, url, picture }) => { return { id, name, displayOrder, description, url, picture } })
                    setWebsites(sorted)
                    // console.log(websites.displayOrder);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.message);
                }
            )
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>
    }
    if (!isLoaded) {
        return <div className="spinner">
            <Spinner animation="border" role="status" >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    }
    if (websites) { //true jakoze pole teda spis asi obket ne? neni prazdne??

        const colCount = 2
        const rows = []
        websites.forEach((w, i) => {
            const rowIndex = Math.floor(i / colCount)
            if (!rows[rowIndex])
                rows[rowIndex] = []

            rows[rowIndex].push(w)
        })

        return rows.map((r, i) => <div key={i} className="row App-library-item-row">
            {r.map((website, wi) =>
                <div key={wi} className={`col-lg-${12 / colCount}`}>
                    <LibraryItem key={website.displayOrder} name={website.name} displayOrder={website.displayOrder} description={website.description} url={website.url}
                        picture={website.picture} />
                </div>)}
        </div>)
    }
}
export default LibraryContainer
