import React from 'react';
import logo from '../logoND.png';

const Header= () => {

  const mainHeadLine = "Knihovna aplikaci";
    return (
        <div>
          <img src={logo} alt="Main logo"/>
          <h1>{mainHeadLine}</h1>
        </div>
    )
        
}
export default Header;