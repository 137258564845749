import React from 'react';

const Footer = () => {

    return (
        <div className='footer'>
            © 2022 <a href="https://www.alfaveo.cz" target="_blank" 
            rel="noopener noreferrer">Alfaveo</a><br>

            </br> Powered by: <a
                href="https://jetveo.io/cs" target="_blank"
                rel="noopener noreferrer">Jetveo.io</a>
        </div>

    );
}
export default Footer